.wrapper {
  padding: 1rem;
}

.wrapper h1 {
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 3.75rem;
}

.wrapper h2 {
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 2.5rem;
  margin-bottom: -3rem;
}

@media (min-width: 768px) {
  .wrapper h1 {
    font-size: 5rem;
  }

  .wrapper h1 {
    margin-left: 2rem;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 6rem;
  }

  .wrapper h2 {
    margin-left: 3.75rem;
  }
}
