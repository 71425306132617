.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.modal {
  font-family: 'Montserrat Regular', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 30vh;
  left: 10%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 17.5rem);
  width: 35rem;
}

.modal button {
  border-radius: 10px;
  min-height: 44px;
  padding-top: 7px;
  background-color: rgba(255, 255, 255, 0.7);
}

.modal button:hover {
  color: black;
  background-color: rgba(255, 255, 255, 1);
}

.modal h3 {
  font-size: xx-large;
  font-weight: bold;
}

.content {
  padding: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}

.modal .closeButton {
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: 0 none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding-top: 2px;
}

.modal .closeButton img {
  height: 1rem;
  width: 1rem;
  opacity: 0.5;
  transition: all 0.125s ease-in-out;
  margin-top: -4px;
}

.modal .closeButton img:hover {
  opacity: 1;
}

.modal button img {
  margin-top: -2px;
  width: 1.5rem;
  height: auto;
}

@media (max-width: 992px) {
  .modal {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
