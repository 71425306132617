.section {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Montserrat Bold', sans-serif;
}

.markdown {
  font-family: 'Montserrat Regular', sans-serif;
  font-size: 1.1rem;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-weight: bold;
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}
