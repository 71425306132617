.voteIcon {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.proposalTitle {
  text-decoration: none;
  color: black;
  font-family: 'Montserrat Regular', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.delegateHover {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}
