.nounButton {
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Montserrat Regular', sans-serif;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  color: var(--brand-color-green);
  background-color: var(--brand-color-green-translucent);
  font-size: 14px;
  width: fit-content;
}

.pass {
  color: var(--brand-color-blue);
  background-color: var(--brand-color-blue-translucent);
}

.fail {
  color: var(--brand-color-red);
  background-color: var(--brand-color-red-translucent);
}

.pending {
  color: var(--brand-color-green);
  background-color: var(--brand-color-green-translucent);
}
