.link,
.link:visited,
.link:active {
  color: var(--brand-dark-green);
  text-decoration: underline;
}

.link:hover {
  color: var(--brand-color);
}

.bidRow {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
}

.currentBidCol {
  border-right: 0 !important;
}
