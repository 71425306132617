.voteModal {
  font-family: 'Montserrat Regular', sans-serif;
}

.propTitle {
  font-family: 'Montserrat Bold', sans-serif;
  font-size: 32px;
  margin-bottom: 5px;
}

.voteIcon {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.submitBtn {
  width: 100%;
  min-width: 8rem;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green) !important;
  border: var(--brand-color-green) !important;
  color: white !important;
}
.submitBtn:focus,
.submitBtn:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
  border: var(--brand-color-green) !important;
}
.submitBtn:active {
  background-color: var(--brand-color-green) !important;
}

.submitBtnDisabled {
  background-color: var(--brand-gray-light-text) !important;
  width: 100%;
  min-width: 8rem;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  border: var(--brand-gray-light-text);
  opacity: 0.5;
  box-shadow: none !important;
}

.submitBtnDisabled:hover,
.submitBtnDisabled:active,
.submitBtnDisabled:focus {
  cursor: not-allowed;
  box-shadow: none !important;
  color: white !important;
}

.header {
  border-bottom: 0px;
}

.votingButtonsWrapper {
  transition: all 0.125s ease-in-out;
}

.loadingSpinner {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--brand-gray-light-text);
}

.transactionStatus {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  font-family: 'Montserrat Regular', sans-serif;
  font-weight: bold;
}

.disabled {
  opacity: 0.5;
}

.voteSuccessBody {
  margin-top: 1rem;
}

.voteFailureTitle {
  font-weight: normal;
  width: 100%;
}

.voteFailureBody {
  margin-top: 1rem;
  font-weight: bold;
}

.voteFailureErrorMessage {
  font-weight: bold;
  color: var(--brand-color-red);
}

.voteReasonTextarea {
  padding-top: 2rem !important;
  height: 6.5rem !important;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.voteReasonTextarea:focus {
  box-shadow: none;
}
