.delegateInput {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 54px;
  color: black;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: white;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'Montserrat Regular', sans-serif;
  font-weight: bold;
  font-size: 25px;
  transition: all 0.2s ease-in-out;
}

.delegateInput:focus {
  box-shadow: none !important;
}

.nonEnBottomMargin {
  margin-bottom: 3.1rem;
}

@media (max-width: 992px) {
  .nonEnBottomMargin {
    margin-bottom: 3.5rem;
  }
}

.empty {
  border: none;
}

.valid {
  border: 2px solid var(--brand-color-green);
}

.invalid {
  border: 2px solid var(--brand-color-red);
}

.delegateWrapper {
  border-top: 1px solid var(--brand-gray-border);
  padding: 1.5rem 0.5rem 0;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ogPunksWrapper {
  border-top: 1px solid var(--brand-gray-border);
  padding: 1.5rem 0.5rem 0;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow-y: auto;
}

.delegateRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
}

.delegateRow:first-of-type {
  margin-top: 0;
}

.delegateRow:last-of-type {
  margin-bottom: 0;
}

.delegateRow .headingWrapper {
  justify-content: space-between;
}

.headingWrapper {
  display: flex;
  align-items: center;
}

.headingText {
  font-family: 'Montserrat Regular', sans-serif;
  color: var(--brand-cool-dark-text);
  font-weight: bold;
  font-size: 22px;
}

.avatarWrapper {
  margin-right: 1rem;
}

.avatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  height: 80%;
}

.ogPunkWrapper {
  display: flex;
  align-items: center;
}

.ogPunkAvatarWrapper {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.ogPunkAvatar {
  background-color: var(--brand-cool-background);
  background-image: url('../../assets/punks.png');
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  overflow: hidden;
  transform: scale(1.5);
  border-radius: 4px;
}

.ogPunkText {
  display: flex;
  flex-direction: column;
}

.ogPunkText span {
  color: var(--brand-cool-light-text);
  font-size: 13px;
}

.delegateTxWrapper {
  display: flex;
  align-items: center;
}

.spinner {
  margin-right: 10px;
}

.noteText {
  color: var(--brand-cool-light-text);
  font-size: 13px;
  margin-bottom: 1rem;
}

.buttonWrapper {
  border-top: 1px solid var(--brand-gray-border);
  padding: 1.5rem 0.5rem 0;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.btnVoteCount {
  align-items: center;
  margin: auto;
}

.delegateKVotesBtn {
  text-align: center;
}

.delegateKVotesBtn span {
  margin-right: 0.3rem;
}

.highlightCircle {
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  background-image: radial-gradient(#ffffff70 15%, rgba(0, 0, 0, 0) 75%);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 20px;
}

.delegateCandidateInfoWrapper {
  padding: 0 0.25rem 0.25rem 0;
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .customButtonHighlighter {
    top: 24.15%;
    left: 81.115%;
  }

  .headingText {
    font-size: 18px;
  }
}

.alreadyDelegatedCopy {
  margin-left: 0.25rem;
  color: var(--brand-cool-light-text);
}
