.voteCardHeaderTextEn {
  font-size: 22px;
  font-family: 'Montserrat Bold', sans-serif;
}

.voteCardHeaderTextNonEn {
  font-size: 16px;
  font-family: 'Montserrat Bold', sans-serif;
}

.voteCardVoteCount {
  font-size: 22px;
  font-family: 'Montserrat Regular', sans-serif;
  font-weight: bold;
  margin-top: 0.15rem;
}

.for {
  color: var(--brand-color-green);
}

.against {
  color: var(--brand-color-red);
}

.abstain {
  color: var(--brand-gray-light-text);
}

.voteCountCard {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #f4f4f8;
  min-height: 18rem;
}

.voteCountCard p {
  display: flex;
  justify-content: space-between;
}

.nounProfilePics {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.mobileVoteCountWrapper {
  display: none !important;
}

.unitTextEn {
  opacity: 0.5;
  font-size: 18px;
  font-weight: 500;
}

.unitTextNonEn {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
}

.smallerVoteCountText {
  font-size: 16px;
}

@media (max-width: 1200px) {
  .voteCountCard {
    min-height: 0rem;
  }

  .unitTextEn {
    font-size: 16px;
  }

  .unitTextNonEn {
    font-size: 13px;
  }

  .mobileVoteCountWrapper {
    display: flex !important;
    flex-direction: column;
  }

  .voteCardVoteCount {
    margin-left: auto;
    margin-right: auto;
  }

  .wrapper {
    width: 33%;
  }

  .voteCardHeaderTextEn {
    margin-left: auto;
    margin-right: auto;
  }

  .voteCardHeaderTextNonEn {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .nounProfilePics {
    display: none;
  }
}
