.wrapper {
  display: flex;
  flex: 1;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0 2rem;
}

.footerDisclaimer {
  font-family: 'Montserrat Regular', sans-serif !important;
  font-weight: 500;
  font-size: 12px;
}

.footerSignature {
  font-family: 'Montserrat Regular', sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  color: var(--brand-black) !important;
  display: flex;
}

.footerSignature a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  margin: 8px 14px;
  transition: all 0.15s ease-in-out;
}

.footerSignature a:hover {
  text-decoration: none;
  font-weight: bold;
}

.footerSignature img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

@media (min-width: 992px) {
  .container {
    flex-direction: row;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
